import Vue from 'vue'
import Vuex from 'vuex'
import {UserTenantQueryByUsername,getLogin,getLoginOut} from '@/api'
import router from '@/router'
import { Message } from 'element-ui';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading:false,
    token:'',
    userInfo:{},
    webSocketMsg:null
  },
  mutations: {
    setLoading(state,data){
      state.isLoading=data
    },
    setUser(state,data){
      state.userInfo=data
    },
    setToken(state,data){
      state.token=data
    },
    web_socket_msg(state,data){
      state.webSocketMsg=data
    }
  },
  actions: {
    
    async getUser(content,{username,password, tenantId}){
      return new Promise(async (resolve, reject) => {
        //const res = await UserTenantQueryByUsername({username:username})
        //if(res.code==200){
          // let tenantId =  res.result.tenantId
          localStorage.setItem('tenantId',tenantId)
          const resp = await getLogin({username:username,password:password})
          // console.log(resp)
          if(resp.code==200){
            const userInfo = resp.result.userInfo
            const token = resp.result.token
            content.commit('setUser',userInfo)
            content.commit('setToken',token)
            localStorage.setItem('userInfo',JSON.stringify(userInfo))
            localStorage.setItem('token',token)
            resolve(resp)
          //}
        }
    });
        
    },
    loginOut(content){
      // const res = await getLoginOut()
      // if(res.code==200){
        content.commit('setUser',{})
        content.commit('setToken','')
        localStorage.clear()
        Message({
          message: '退出成功！'
        });
        router.push('/login')
      // }
    }
  },
  modules: {
  }
})
