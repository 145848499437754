const Amount = value=>{
    if(!value) return '0.00';
    //获取整数部分
    var intPart =  Math.floor(value); 
    //将整数部分逢三一断
    var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); 
    //预定义小数部分
    var floatPart = ".00"; 
    if(value.toString().indexOf('.')==-1){
        return Number(value)
    }
    var value2Array = value.toString().split(".");
    //=2表示数据有小数位
    if(value2Array.length == 2) {
        floatPart = value2Array[1].toString(); //拿到小数部分
        console.log('111',intPartFormat + "." + floatPart)
        if(floatPart.length == 1) {
            return intPartFormat + "." + floatPart + '0';
        } else {
            return Number(intPartFormat + "." + floatPart).toFixed(2);
        }
    } else {
        return Number(intPartFormat + floatPart).toFixed(2);
    }
 
}
export {
    Amount
}