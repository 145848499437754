<template>
  <div class="home_page">
    <div class="nav_list">
      <div  class="logo_content" v-on:click="toggleFullScreen">
        <img src="../assets/img/logo.png" alt="">
        <div>多上客</div>
      </div>
      <transition name="el-zoom-in-top" v-for="(item,index) in navList" :key="index">
          <router-link tag="div" class="nav_item" :to="item.path">
            <div class="left_border"></div>
            <div class="nav_box">
                <span :class="item.icon"></span>
                <span>{{item.label}}</span>
            </div>
          </router-link>
      </transition>
    </div>
    <div class="content_main">
      <router-view @fullScreenEvent="toggleFullScreen" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  data(){
    return{
      activeNav:1,
      navList:[
        {id:0,path:'/home',label:'首页',icon:'dwb dwb-shouye'},
        {id:1,path:'/billing',label:'开单',icon:'dwb dwb-dingdan'},
        {id:2,path:'/card',label:'开卡',icon:'dwb dwb-yinhangqia'},
        //{id:3,path:'appointment',label:'预约',icon:'dwb dwb-yuyue'},
        {id:4,path:'/member',label:'会员',icon:'dwb dwb-hrstaffarchives'},
        {id:5,path:'/report',label:'报表分析',icon:'dwb dwb-baobiao'},
        // {id:6,path:'/balance',label:'余额报表',icon:'dwb dwb-baobiao'}
      ]
    }
  },


  methods:{
    fullscreenEnabled() {
      return (
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement
      );
    },
    toggleFullScreen(){
      console.log("fullScreen...");

      let elem = document.getElementById("root");

      var u = navigator.userAgent;

      var isiOS = false;
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        //alert(navigator.userAgent);
        alert("ios");
        isiOS = true;
      }
   
      if(isiOS){ //判断是否为ios终端
        if (!this.fullscreenEnabled()) {
          elem.webkitEnterFullscreen(); // 开启全屏
        } else {
            this.cancelFullscreen();
        }
      }else{
        if (!this.fullscreenEnabled()) {
            elem.requestFullscreen().catch((err) => {
              alert(
                `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
              );
            });
          } else {
            this.cancelFullscreen();
          }
        }
    },
    cancelFullscreen() {

      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
}

</script>

<style lang="less" scoped>
.home_page{
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  .nav_list{
    width: 76px;
    height: 100%;
    background-color: #060E20;
    box-shadow: 0px 2px 16px 0px rgba(209, 242, 237, 0.75);
    box-sizing: border-box;
    overflow: auto;
    padding: 20px 0;
    z-index: 999;
    .logo_content{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 35px;
      cursor: pointer;
      img{
        width: 45px;
        margin-bottom: 7.5px;
      }
    }
    .nav_item{
      width: 100%;
      height: 75px;
      opacity: 0.8;
      border-left: 3px solid rgba(0,0,0,0);
      display: flex;
      justify-content: space-between;
      padding-right: 10px;
      box-sizing: border-box;
      align-items: center;
      .left_border{
        width: 3px;
        height: 50px;
        border-radius: 3px;
        background-color: rgba(0,0,0,0);
      }     
      .nav_box{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #ccc;
        flex-direction: column;
        flex-wrap: wrap;
        width: 44px;
        min-height: 50px;
        box-sizing: border-box;
        padding: 3px;
        text-align: center;
        .dwb{
          font-size: 20px;
        }
      }
      &.router-link-active{
          .left_border{
            background-color: #5087FF;
          }
          .nav_box{
            background: #5087FF;
            color: #FFFFFF;
            box-sizing: border-box;
            border-radius: 6px;
            .dwb{
              color: #FFFFFF;
            }
          }
        }
      &:hover{
        cursor: pointer;
        .left_border{
          background-color: #5087FF;
        }
        .nav_box{
          background: #5087FF;
          border-radius: 6px;
          color: #FFFFFF;
        }
      }
    }
  }
  .content_main{
    width: calc(100% - 76px);
    // flex: 1;
    height: 100%;
    background-color: #F3F4F8;
    padding-bottom: 10px;
    // overflow:auto;
  }
}
  
</style>
