import axios from 'axios';
import { Message } from 'element-ui';
import store from '@/store';
import router from '@/router';
// console.log(process.env.VUE_APP_BASE_API)
var num = 0
// 创建axios实例：service
const service = axios.create({
  // baseURL: '/',
  // headers: {
  //   'Content-Type': 'application/json',
  // },
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // 设置请求超时。如果超过了60秒，就会告知用户当前请求超时，请刷新等
});
// 环境的切换
// if (process.env.NODE_ENV == 'dev') {  
  // axios.defaults.baseURL = 'http://mk.bestquickpay.com/mkcrm/';
// }else if (process.env.NODE_ENV == 'prod') {    
  // axios.defaults.baseURL = 'http://mk.bestquickpay.com/mkcrm/';
// }
// 请求拦截器
service.interceptors.request.use(
  config => {
    store.state.isLoading=true
    if (config.method === 'get') {
      //  给data赋值以绕过if判断
      config.data = true
      config.headers['Content-Type'] = 'application/json';
    }
    const tenantId = localStorage.getItem('tenantId');
    if (tenantId) {
      config.headers['tenant-id'] = tenantId; 
    }
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['X-Access-Token'] = token; 
    }
    num++
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    if(response.data.code=='401'){
      router.push('/login')
    }
    if (response.status !== 200) {
      Message({
        message: response.message || 'error',
        type: 'error',
        duration: 3000,
        showClose: true
      });
      return Promise.reject(response.message || 'error');
    } else {
      num--
      if (num <= 0) {
        store.state.isLoading=false     
      } else {
        store.state.isLoading=true 
      }
      if(response.data.code!==200 && response.data.code!==0){
        Message({
          message: response.data.message,
          type: 'error',
          duration: 3000,
          showClose: true
        });
      }
      //正常拿到数据
      return response.data;
    }
  },
  error => {
    store.state.isLoading=false
    Message({
      message: error.message || 'error',
      type: 'error',
      duration: 6000,
      showClose: true
    });
    return Promise.reject(error);
  }
);


export default service;