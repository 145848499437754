<template>
  <div id="app" 
    v-loading="isLoading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)">
    <router-view>
      <Index></Index>
    </router-view>

  </div>
</template>
<script>
import Index from '@/views/index.vue'
import {mapState} from 'vuex'
export default {
  components: {
    Index
  },
  mounted(){
 
  },
  computed:{
    ...mapState(['isLoading'])
  },
  methods:{

  }
}
</script>
<style lang="less">
@import "./assets/css/main.css";
</style>
